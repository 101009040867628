<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.87293 2.89137C9.77612 2.02904 11.9016 1.78453 13.9509 2.19215C16.0002 2.59978 17.8703 3.63908 19.2986 5.16409C20.727 6.68909 21.6417 8.62319 21.9144 10.6947C22.1872 12.7663 21.8042 14.8713 20.8192 16.714C19.8343 18.5567 18.2968 20.0446 16.4229 20.9687C14.5489 21.8929 12.4326 22.2068 10.371 21.8664C8.30952 21.5261 6.40638 20.5485 4.92893 19.0711C4.53841 18.6805 4.53841 18.0474 4.92893 17.6569C5.31946 17.2663 5.95262 17.2663 6.34314 17.6569C7.52511 18.8388 9.04761 19.6209 10.6968 19.8932C12.3461 20.1654 14.0391 19.9143 15.5383 19.175C17.0375 18.4357 18.2674 17.2454 19.0554 15.7712C19.8433 14.297 20.1497 12.613 19.9316 10.9558C19.7134 9.29855 18.9816 7.75127 17.8389 6.53127C16.6963 5.31127 15.2002 4.47983 13.5607 4.15373C11.9213 3.82762 10.2209 4.02324 8.69835 4.7131C7.17579 5.40296 5.90757 6.5524 5.0718 8.00001L8 8.00001C8.55229 8.00001 9 8.44772 9 9.00001C9 9.55229 8.55229 10 8 10H3.5C2.67158 10 2 9.32844 2 8.50001V4.00001C2 3.44772 2.44772 3.00001 3 3.00001C3.55228 3.00001 4 3.44772 4 4.00001L4 5.99999C5.00519 4.65974 6.3353 3.58806 7.87293 2.89137Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#1F284D' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.3498 4C13.488 4 13.6 4.11201 13.6 4.25018V4.90519C13.6 5.43288 13.9674 5.8852 14.4547 6.08774C14.942 6.29031 15.512 6.22529 15.8852 5.85209L16.3486 5.3887C16.4463 5.29099 16.6047 5.29099 16.7024 5.3887L18.6113 7.2976C18.709 7.39531 18.709 7.55373 18.6113 7.65143L18.148 8.11478C17.7748 8.48799 17.7097 9.05795 17.9123 9.54532C18.1149 10.0326 18.5672 10.4 19.0949 10.4H19.7498C19.888 10.4 20 10.512 20 10.6502V13.3498C20 13.488 19.888 13.6 19.7498 13.6H19.095C18.5673 13.6 18.1149 13.9674 17.9124 14.4547C17.7098 14.9421 17.7748 15.5121 18.148 15.8853L18.6113 16.3486C18.709 16.4463 18.709 16.6047 18.6113 16.7024L16.7024 18.6113C16.6047 18.709 16.4463 18.709 16.3486 18.6113L15.8853 18.1481C15.5121 17.7749 14.9421 17.7098 14.4547 17.9124C13.9674 18.115 13.6 18.5673 13.6 19.0951V19.7498C13.6 19.888 13.488 20 13.3498 20H10.6502C10.512 20 10.4 19.888 10.4 19.7498V19.0951C10.4 18.5673 10.0326 18.115 9.54527 17.9124C9.05787 17.7098 8.48789 17.7749 8.11466 18.1481L7.65142 18.6113C7.55372 18.709 7.39532 18.709 7.29761 18.6113L5.38869 16.7024C5.29098 16.6047 5.29098 16.4463 5.38869 16.3486L5.85197 15.8853C6.22518 15.5121 6.29021 14.9421 6.08763 14.4547C5.88509 13.9674 5.43274 13.6 4.90501 13.6H4.25016C4.112 13.6 4 13.488 4 13.3498V10.6502C4 10.512 4.11201 10.4 4.25018 10.4H4.90513C5.43283 10.4 5.88515 10.0326 6.08769 9.54532C6.29027 9.05795 6.22526 8.488 5.85205 8.11479L5.3887 7.65144C5.29099 7.55373 5.29099 7.39531 5.3887 7.2976L7.29759 5.3887C7.39531 5.29099 7.55373 5.29099 7.65144 5.3887L8.11484 5.85209C8.48804 6.22529 9.05797 6.29031 9.54533 6.08774C10.0326 5.88521 10.4 5.43289 10.4 4.9052V4.25018C10.4 4.11201 10.512 4 10.6502 4H13.3498ZM12 16.7999C14.651 16.7999 16.8 14.6509 16.8 11.9999C16.8 9.34897 14.651 7.19994 12 7.19994C9.34905 7.19994 7.20001 9.34897 7.20001 11.9999C7.20001 14.6509 9.34905 16.7999 12 16.7999Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
  },
};
</script>
